@use "styles/variables" as *;
@use "styles/mixins" as *;

$mobile-xs-nav-bar-height: 80px;
$mobile-nav-bar-height: 100px;
$nav-bar-height: 120px;

.no-scroll {
  overflow: hidden;
  @include breakpoint($breakpoint-sm) {
    overflow: auto;
    overflow-x: hidden;
  }
}

.nav-wrapper {
  position: fixed;
  top: 0;
  z-index: 100;
  animation: slide-down 0.5s ease-in-out;
  width: 100%;
  transition: background-color 0.3s ease-in-out;

  &.scrolled {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    nav {
      color: var(--secondary-color);
    }
  }
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  cursor: pointer;
  z-index: 10;
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
  transform-origin: center;
  margin-left: auto;
  margin-top: 16px;
  margin-bottom: 16px;

  @include breakpoint($breakpoint-sm) {
    display: none;
    position: absolute;
  }

  .hamburger__line {
    width: 30px;
    height: 4px;
    background-color: gray;
    margin: 4px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    &:nth-child(1) {
      transform: rotate(0deg) translate(0, 0);
    }
    &:nth-child(2) {
      transform: rotate(0deg) translate(0, 0);
    }
    &:nth-child(3) {
      transform: rotate(0deg) translate(0, 0);
    }
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $mobile-xs-nav-bar-height;
  color: var(--white-color);

  .logo {
    display: flex;
    align-items: center;
    img {
      width: auto;
      height: 50px;
    }
  }

  ul {
    display: none;
    list-style: none;
    li {
      margin-left: 2.5rem;
      a {
        font-family: var(--secondary-font);
        color: inherit;
        text-decoration: none;
        font-size: 1.5rem;
      }
    }
  }

  @include breakpoint($breakpoint-xs) {
    height: $mobile-nav-bar-height;
    .logo img {
      height: 60px;
    }
  }

  @include breakpoint($breakpoint-sm) {
    height: $nav-bar-height;
    .logo img {
      height: 80px;
    }
    ul {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .open {
    height: 150vh;
    background-color: var(--white-color);
    display: grid;
    grid-template-areas: 
      "logo menu"
      "links links";
    row-gap: 32px;
    grid-template-rows: $mobile-xs-nav-bar-height 1fr;

    @include breakpoint($breakpoint-xs) {
      grid-template-rows: $mobile-nav-bar-height 1fr;
    }
  
    a {
      grid-area: logo;
      justify-self: start;
    }
    .nav-toggle {
      grid-area: menu;
    }
  
    ul {
      grid-area: links;
      flex-shrink: 0;
      display: flex;
      color: var(--secondary-color);
      flex-direction: column;
      align-self: flex-start;
      gap: 32px;
      li {
        margin-left: 0px;
        a {
          width: 100%;
          font-size: 2rem;
          display: block;
          &:hover {
            color: var(--primary-color);
            display: block;
          }
        }
      }
    }
  
    .hamburger {
      transform: rotate(90deg);
      .hamburger__line {
        &:nth-child(1) {
          transform: rotate(45deg) translate(8px, 8px);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translate(10px, -9px);
        }
      }
    }
  }
}


@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
