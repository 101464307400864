
@use 'styles/layout';
@use 'styles/nav';
@use 'styles/footer';
@use "styles/variables" as *;
@use "styles/mixins" as *;

// Landing section
.landing {
    background: url("images/landing.jpg") no-repeat center center/cover;
    background-color: rgba(30, 28, 33, 0.72);
    background-blend-mode: multiply;
    
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        color: var(--white-color);
        text-align: center;
        padding: 0 1rem;
        margin: 0 auto;

        h1 {
            font-size: 3.5rem;
            line-height: 1.15em;
            letter-spacing: 0.08em;
            padding-top: 100px;
        }

        p {
            font-family: var(--secondary-font);
            font-size: 1.4rem;
            line-height: 2.5rem;
            letter-spacing: 0.1em;
            margin-top: 1rem;
        }

        button {
            margin: 60px auto;
        }

        @include breakpoint($breakpoint-xs) {
            padding: 0 1.5rem;

            h1 {
                font-size: 4rem;
                padding-top: 120px;
            }

            p {
                font-family: var(--secondary-font);
                font-size: 1.5rem;
                margin-top: 2rem;
            }

            button {
                margin-top: 80px;
            }
        }
        @include breakpoint($breakpoint-sm) {
            min-height: 800px;
            max-width: 880px;
            margin: 0;
            color: var(--white-color);
            text-align: left;
            padding: 0;

            h1 {
                font-size: 4.5rem;
            }

            p {
                font-family: var(--secondary-font);
                font-size: 1.5rem;
            }

            button {
                align-self: flex-start;
                margin: 80px 0 0 0;
            }
        }
    }
}


.clients {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    header {
        color: var(--gray-color);
        opacity: 0.5;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        gap: 2rem;

        h4 {
            font-size: inherit;
            letter-spacing: 5px;
        }
    }

    &__carousel {
        margin-top: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__list {
        display: flex;
        gap: 8rem;
        overflow: hidden;
        max-width: max-content;
        justify-content: center;
        padding: 0 5rem;
        img {
            height: 4rem;
        }
    }
}


.about {
    background-color: #1E1C21;
    overflow: hidden;
    .wrapper {
        position: relative;
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }
    &__bg {
        content: "";
        height: 2000px;
        width: 60px;
        top: -80px;
        left: 240px;
        z-index: 0;
        position: absolute;
        background-color: #fff;
        opacity: 0.1;
        transform: rotate(50deg);
        transform-origin: top left;

        &::before {
            content: " ";
            position: absolute;
            height: 100%;
            width: 100px;
            top: -80px;
            left: -130px;
            background-color: #fff;
            transform-origin: top left;
        }

        &::after {
            content: " ";
            position: absolute;
            height: 100%;
            width: 60px;
            top: -80px;
            left: 80px;
            background-color: #fff;
            transform-origin: top left;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        color: var(--white-color);
        display: flex;
        flex-direction: column;
        gap: 2rem;

        h2 {
            font-size: var(--title-font-size-sm);
            font-weight: normal;
        }

        p {
            font-family: var(--secondary-font);
            font-size: 1.5rem;
            line-height: 135%;
        }

        .info {
            flex-basis: 42%;
            flex-shrink: 0;
        }

        .image-grid {
            display: flex;
            flex: auto;
            justify-content: space-between;
            position: absolute;
            z-index: -1;
            height: 100%;
            bottom: 0;
            img {
                width: 30%;
                height: 100%;
                object-fit: cover;
                filter: brightness(0.4);
            }
        }

        @include breakpoint($breakpoint-sm) {
            flex-direction: row;

            h2 {
                font-size: var(--title-font-size);
            }
            .image-grid {
                position: initial;
                z-index: unset;
                bottom: unset;
                height: auto;
                img {
                    filter: none;
                }
            }
        }
    }
}

.services {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 0.5rem;
        flex-direction: column;
        margin-bottom: 2rem;

        i {
            position: absolute;
        }
        h2 {
            font-size: var(--title-font-size-sm);
            line-height: var(--title-font-size);
            font-weight: normal;
            flex-shrink: 0;
            margin-bottom: 0;
        }
        p {
            font-family: var(--secondary-font);
            font-size: 1.125rem;
            line-height: 1.75rem;
            margin-top: 0.5rem;
            text-align: center;
        }

        @include breakpoint($breakpoint-sm) {
            flex-direction: row;
            gap: 3rem;
            h2 {
                font-size: var(--title-font-size);
            }

            p {
                text-align: left;
            }
        }
    }



    &__categories {
        text-align: center;
        display: none;
        span {
            padding: 1rem;
            font-family: var(--secondary-font);
            position: relative;
            white-space: nowrap;
            &:not(:last-child)::after {
                content: " ";
                position: absolute;
                height: 0.8rem;
                width: 1px;
                right: 0;
                transform: translateY(0.3rem);
                opacity: 0.15;
                background-color: #1E1C21;
            }
        }

        @include breakpoint($breakpoint-xs) {
            display: block;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        justify-content: space-evenly;
        row-gap: 4rem;
        column-gap: 2rem;
        margin-top: 3rem;

        @include breakpoint($breakpoint-sm) {
            grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
        }

        .service {
            flex-direction: column;
            display: flex;

            .content {
                padding: 0 1.5rem;
                height: 100%;
                flex-direction: column;
                display: flex;
                align-items: start;
                gap: 1rem;
                margin-top: 1.5rem;
            }

            img {
                width: 100%;
                height: 11.875rem;
                object-fit: cover;
                border-radius: 0.75rem;

                &.contain {
                    object-fit: contain;
                }
            }
            h3 {
                font-size: 2rem;
                font-weight: normal;
            }
            p {
                font-family: var(--secondary-font);
                font-size: 1.125rem;
                line-height: 1.75rem;
                margin-bottom: 1rem;
            }

            button {
                margin-top: auto;
            }
        }
    }
}


.reviews {
    padding-bottom: 6rem;
    h2 {
        font-size: var(--title-font-size-sm);
        font-weight: normal;
        text-align: center;
    }

    @include breakpoint($breakpoint-sm) {
        h2 {
            text-align: left;
            font-size: var(--title-font-size);
        }
    }

    &__control-dots {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-top: 2rem;
        span {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: var(--gray-color);
            cursor: pointer;
            transition: background-color 0.3s;
            &:hover {
                background-color: var(--primary-color);
            }
        }

        .active {
            background-color: var(--primary-color);
        }
    }
}

.review {
    text-align: center;
  
    &__content {
        padding: 0;
        p {
            padding: 0 2.5rem;
            font-family: var(--secondary-font);
            font-size: 1.5rem;
            line-height: 2.5rem;
            position: relative;
            &::before {
                position: absolute;
                left: 0;
                content: open-quote;
                font-size: 4rem;
                line-height: 1;
                margin-right: 0.5rem;
                opacity: 0.5;
            }
        
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                content: close-quote;
                font-size: 4rem;
                line-height: 1;
                margin-left: 0.5rem;
                opacity: 0.5;
            }
        }

        cite {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            img {
                height: 1.2rem;
            }
            span {
                font-style: normal;
                font-weight: normal;
                font-size: 1.125rem;
            }
        }
    }

    @include breakpoint($breakpoint-xs) {
        &__content {
            padding: 0 3rem;

            p {
                padding: 0 2rem;
            }
        }
    }
}

.promotional {
    background-color: var(--primary-color);
    padding-top: 6rem;
    padding-bottom: 6rem;
    overflow: hidden;

    .wrapper {
        position: relative;
    }

    &__bg {
        content: "";
        height: 2000px;
        width: 60px;
        top: -180px;
        right: -400px;
        z-index: 0;
        position: absolute;
        background-color: #fff;
        opacity: 0.1;
        transform: rotate(50deg);
        transform-origin: top left;

        &::before {
            content: " ";
            position: absolute;
            height: 100%;
            width: 100px;
            top: -80px;
            left: -130px;
            background-color: #fff;
            transform-origin: top left;
        }

        &::after {
            content: " ";
            position: absolute;
            height: 100%;
            width: 60px;
            top: -80px;
            left: 80px;
            background-color: #fff;
            transform-origin: top left;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        color: var(--white-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

        h3 {
            font-size: 2rem;
            line-height: 3rem;
            font-weight: normal;
            max-width: 800px;
            text-align: center;
        }

        button {
            flex-shrink: 0;
        }

        @include breakpoint($breakpoint-sm) {
            flex-direction: row;

            h3 {
                text-align: left;
            }
        }
    }
}
