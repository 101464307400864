@use "styles/variables" as *;
@use "styles/mixins" as *;


.footer {
    background-color: #1E1C21;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: var(--white-color);

    a {
        color: var(--white-color);
        text-decoration: underline;
    }

    &__content{
        display: grid;
        grid-template-areas: 
            "links links"
            "careers careers"
            "logo info";
        justify-items: center;
        column-gap: 0.5rem;
        width: 100%;
        row-gap: 2rem;
        grid-template-columns: 1fr 1fr;
        padding: 0 1.25rem;
        margin-bottom: 1rem;
    }

    &__logo {
        grid-area: logo;
        justify-self: end;

        img {
            height: 80px;
            object-fit: contain;
        }
    }

    &__info {
        justify-self: start;
        grid-area: info;
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        color: var(--white-color);
        font-family: var(--secondary-font);
    }

    &__info-item {
        display: flex;
    }

    &__nav {
        grid-area: links;
        text-align: center;
        header {
            font-size: 1.25rem;
            font-weight: bold;
            letter-spacing: 0.4rem;
            text-transform: uppercase;
            margin-bottom: 1.5rem;
        }
        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            gap: 0.6rem;
            font-family: var(--secondary-font);
            li {
                a {
                    font-family: var(--secondary-font);
                    color: var(--white-color);
                    font-size: 1.5rem;
                }
            }
        }

        @include breakpoint($breakpoint-sm) {
            margin-left: auto;
        }
    }

    &__careers {
        grid-area: careers;

        text-align: center;
        header {
            font-size: 1.25rem;
            font-weight: bold;
            letter-spacing: 0.4rem;
            text-transform: uppercase;
            margin-bottom: 1.5rem;
        }
        button {
            white-space: nowrap;
        }
    }

    &__terms-and-conditions {
        text-align: center;
    }

    &__copy {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid darkgray;
        text-align: center;
    }

    @include breakpoint($breakpoint-xs) {
        &__content {
            gap: 2rem;
            grid-template-areas: 
                "links careers"
                "logo info";
            grid-template-columns: 1fr 1fr;
        }

        &__nav, &__careers {
            text-align: left;
        }
    }

    @include breakpoint($breakpoint-sm) {
        &__content {
            display: flex;
            gap: 2rem;
            justify-items: center;
            flex-direction: row;
            align-items: initial;
            margin-bottom: 0px;
        }
        &__logo {
            flex-shrink: 0;
            flex-grow: 0;
            img {
                height: 200px;
            }
        }

        &__info {
            align-self: end;
            padding-bottom: 32px;
            gap: 1rem;
        }
    }
}
