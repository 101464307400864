@use "styles/variables" as *;
@use "styles/mixins" as *;

// CSS Reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: 12px;

    --primary-color: #F15B2A;
    --secondary-color: #333;
    --white-color: #fff;
    --gray-color: #7C7C7C;

    --primary-font: "Gabarito", sans-serif;
    --secondary-font: "Merriweather Sans", sans-serif;

    --border-radius: 0.75rem;

    --title-font-size: 4.375rem;
    --title-font-size-sm: 3.75rem;
}

body {
    font-family: "Gabarito", Arial, sans-serif;
    background-color: #f0f0f0;
    color: #333;
}

// Utility classes
.container {
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-4 {
    margin-top: 4rem;
}
.mt-5 {
    margin-top: 6rem;
}

.wrapper {
    padding: 0 1.25rem;
    max-width: 1200px;
    margin: 0 auto;
}

strong {
    font-weight: bold;
}

.highligted {
    color: var(--primary-color);
}

// buttons
.btn {
    font-size: inherit;
    padding: 1.25rem 2.5rem;
    display: inline-flex;
    align-items: center;
    border: none;
    cursor: pointer;
    border-radius: var(--border-radius);
    transition: background-color 0.3s;

    i {
        margin-left: 0.5rem;
    }

    &-primary {
        background-color: var(--primary-color);
        font-family: var(--secondary-font);
        color: var(--white-color);
        font-size: 1.5rem;
        &:hover {
            background-color: var(--secondary-color);
        }
    }

    &-primary-outlined {
        font-family: var(--secondary-font);
        color: var(--primary-color);
        font-size: 1.5rem;
        border: 2px solid var(--primary-color);
        background-color: transparent;
        &:hover {
            background-color: var(--primary-color);
            color: var(--white-color);
        }
    }

    &-secondary {
        background-color: var(--white-color);
        color: var(--primary-color);
        font-family: var(--secondary-font);
        font-size: 1.5rem;
        &:hover {
            background-color: var(--primary-color);
            color: var(--white-color);
        }
    }
}

// link
.link {
    font-size: 1.5rem;
    color: var(--primary-color);
    font-family: var(--secondary-font);
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

// icons
.icon {
    font-size: inherit;
    display: inline-block;
    height: 1em;
    width: 1em;
    &__arrow-right {
        content: " ";
        background: url("images/arrow-right.svg") no-repeat center center/cover;
    }

    &__chevron-left {
        content: " ";
        background: url("images/chevron-left.svg") no-repeat center center/contain;
    }
    &__chevron-right {
        content: " ";
        background: url("images/chevron-right.svg") no-repeat center center/contain;
    }

    &__sage {
        content: " ";
        background: url("images/sage.svg") no-repeat center center/contain;
    }
}
